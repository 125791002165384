import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// Custom imports
import { createResponseInterceptor } from "@globalholdings/ui-common-functions";
import { Loading } from "@globalholdings/ui-common-components";
import Layout from "../../containers/Layout";
import PrivateRoute from "../../components/PrivateRoute";
import LoginRedirect from "../../components/LoginRedirect";

const Exceptions = lazy(() => import("../Exceptions"));
const Deferrals = lazy(() => import("../Deferrals"));
const History = lazy(() => import("../History"));
const DirectPayExceptions = lazy(() => import("../DirectPayExceptions"));
const PaymentsPending = lazy(() => import("../PaymentsPending"));
const DeferralsPending = lazy(() => import("../PendingDeferrals"));

export default function App() {
  createResponseInterceptor(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_CLIENT_SECRET
  );
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute
              exact
              path="/deferrals"
              component={() => <Deferrals />}
            />
            <PrivateRoute
              exact
              path="/exceptions"
              component={() => <Exceptions />}
            />
            <PrivateRoute exact path="/history" component={() => <History />} />
            <PrivateRoute
              exact
              path="/directpay"
              component={() => <DirectPayExceptions />}
            />
            <PrivateRoute
              exact
              path="/payments-pending"
              component={() => <PaymentsPending />}
            />
            <PrivateRoute
              exact
              path="/deferrals-pending"
              component={() => <DeferralsPending />}
            />
            <Route
              exact
              path="/"
              component={() => (
                <LoginRedirect loadingComponent={() => <Loading />} />
              )}
            />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
}
