import React, { createContext, useState } from "react";

export const SelectedCompaniesContext = createContext([]);

export const SelectedCompaniesProvider = ({ children }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  return (
    <SelectedCompaniesContext.Provider
      value={{ selectedCompanies, setSelectedCompanies }}
    >
      {children}
    </SelectedCompaniesContext.Provider>
  );
};
