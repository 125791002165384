import React, { Fragment, useState, useEffect, useCallback } from "react";
import { withRouter, NavLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Typography, Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
// Custom imports
import {
  userIsAuthenticated,
  fetchApi,
  logOut,
} from "@globalholdings/ui-common-functions";
import {
  SessionModal,
  SessionTimer,
} from "@globalholdings/ui-common-components";
import { CompaniesContext } from "../../context/Companies";
import { CountsContext } from "../../context/Counts";
import { PermissionsContext } from "../../context/Permissions";
import BrandMark from "./brandMark.svg";
import { SelectedCompaniesProvider } from "../../context/SelectedCompanies";
import getApiEnvironment from "../../helpers/GetApiEnvironment";
import FeatureFlag from "../../components/FeatureFlag";

const Layout = (props) => {
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [counts, setCounts] = useState({});
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [pendingPaymentsCompanies, setPendingPaymentsCompanies] = useState([]);
  const [primaryNavSelection, setPrimaryNavSelection] = React.useState(0);
  const [secondaryNavSelection, setSecondaryNavSelection] = React.useState(0);
  const [tertiaryNavSelection, setTertiaryNavSelection] = React.useState(0);
  const [hideThirdNavBar, setHideThirdNavBar] = React.useState(false);
  const [headerHeightThreeColumn, setHeaderHeightThreeColumn] =
    React.useState(177);
  const [headerHeightTwoColumn, setHeaderHeightTwoColumn] = React.useState(120);
  const [headerHeight, setHeaderHeight] = React.useState(
    headerHeightThreeColumn
  );

  const handleOpenSessionModal = () => {
    setSessionModalOpen(true);
  };

  const handleCloseSessionModal = () => {
    setSessionModalOpen(false);
  };

  const updateCountsCallbacks = {
    updateCounts: () => {
      getExceptionCount();
      getDeferralsCount();
    },
  };

  const getExceptionCount = useCallback(() => {
    if (companies.length > 0) {
      const companyIds = companies
        .map((company) => {
          return company.companyId;
        })
        .join(",");
      const exceptionsCountEndpoint = `${window.CONFIG.APP_API_URL}/Exceptions/Next/Count?companyId=${companyIds}`;
      fetchApi("GET", exceptionsCountEndpoint, null, false)
        .then((response) => {
          if (response.status === 200) {
            setCounts((counts) => {
              return {
                ...counts,
                0: response.data,
              };
            });
          } else {
            setCounts((counts) => {
              return {
                ...counts,
                0: 0,
              };
            });
          }
        })
        .catch((error) => {
          setCounts((counts) => {
            return {
              ...counts,
              0: 0,
            };
          });
        });
    }
  }, [companies]);

  const getDeferralsCount = useCallback(() => {
    if (companies.length > 0) {
      const companyIds = companies
        .map((company) => {
          return company.companyId;
        })
        .join(",");
      const deferralsCountEndpoint = `${window.CONFIG.APP_API_URL}/Exceptions/Deferred/Count?companyId=${companyIds}`;
      fetchApi("GET", deferralsCountEndpoint, null, false)
        .then((response) => {
          if (response.status === 200) {
            setCounts((counts) => {
              return {
                ...counts,
                1: response.data,
              };
            });
          } else {
            setCounts((counts) => {
              return {
                ...counts,
                1: 0,
              };
            });
          }
        })
        .catch((error) => {
          setCounts((counts) => {
            return {
              ...counts,
              1: 0,
            };
          });
        });
    }
  }, [companies]);
  const getPendingExceptionsCounts = (endPoint, body, countIndex) => {
    fetchApi("Post", endPoint, body, countIndex)
      .then((response) => {
        if (response.status === 200) {
          setCounts((counts) => {
            return countIndex === 2
              ? {
                  ...counts,
                  2: response.data.paging.rowCount,
                }
              : { ...counts, 3: response.data.paging.rowCount };
          });
        } else {
          setCounts((counts) => {
            return countIndex === 2
              ? {
                  ...counts,
                  2: 0,
                }
              : { ...counts, 3: 0 };
          });
        }
      })
      .catch((error) => {
        setCounts((counts) => {
          return countIndex === 2
            ? {
                ...counts,
                2: 0,
              }
            : { ...counts, 3: 0 };
        });
      });
  };
  const getPendingPaymentsCompanies = async () => {
    const companiesEndpoint = `${getApiEnvironment()}.gateway.ghllc.com/Users/LinkedUsers`;
    await fetchApi("GET", companiesEndpoint)
      .then((response) => {
        if (response.status === 200) {
          setPendingPaymentsCompanies(response.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    let pendingExceptionBody = {
      pendingExceptionFilter: {
        companyId: pendingPaymentsCompanies
          .map((company) => company.companyId)
          .toString(),
      },
      pagingInfo: {
        pageSize: 1,
        pageNumber: 1,
        rowCount: 0,
        pageCount: 0,
      },
    };
    let pendingDeferralsBody = {
      deferredExceptionFilter: {
        companyId: pendingPaymentsCompanies
          .map((company) => company.companyId)
          .toString(),
      },
      pagingInfo: {
        pageSize: 1,
        pageNumber: 1,
        rowCount: 0,
        pageCount: 0,
      },
    };
    const pendingPaymentsEndpoint = `${getApiEnvironment()}.gateway.ghllc.com/Payments/Exceptions/Pending`;
    const deferralsEndpoint = `${getApiEnvironment()}.gateway.ghllc.com/Payments/Exceptions/Pending/deferrals`;
    getPendingPaymentsCompanies();
    if (
      userIsAuthenticated() &&
      pendingPaymentsCompanies.length !== 0 &&
      HasAccessToPendingExceptions() &&
      window.location.pathname === "/payments-pending"
    ) {
      getPendingExceptionsCounts(
        pendingPaymentsEndpoint,
        pendingExceptionBody,
        2
      );
      getPendingExceptionsCounts(deferralsEndpoint, pendingDeferralsBody, 3);
    }
  }, [
    window.location.pathname,
    pendingPaymentsCompanies?.length,
    currentPermissions,
  ]);

  useEffect(() => {
    if (userIsAuthenticated()) {
      const getCompanies = () => {
        if (window.CONFIG) {
          const companiesEndpoint = `${window.CONFIG.APP_API_URL}/users/companies`;
          fetchApi("GET", companiesEndpoint)
            .then((response) => {
              if (response.status === 200) {
                setCompanies(response.data);
                // CLEAR THE LOCAL STORAGE IF IT'S A DIFFERENT USER
                // Get the companies of the current user
                const companiesArray = response.data.map(
                  (company) => company.companyId
                );
                //first check the user is the same as the last logged in.
                if (
                  localStorage.getItem("currentUser") !==
                  sessionStorage.getItem("userName")
                ) {
                  localStorage.clear();
                }
                // Get the companies in the local storage if there's any
                if (localStorage.getItem("exceptionFilters")) {
                  const companiesInLocal = JSON.parse(
                    localStorage.getItem("exceptionFilters")
                  ).companyIdSelector;
                  // Try to find a value in local companies array that is not included in the companies of the current user
                  const found = companiesInLocal.some(
                    (r) => !companiesArray.includes(r)
                  );
                  // If it's found, clear the local storage
                  if (found) {
                    localStorage.clear();
                  }
                }
              }
            })
            .catch((error) => {
              // do nothing
            });
        }
      };
      getCompanies();
    }
  }, [window]);

  useEffect(() => {
    if (HasAccessToPhonePayExceptions) {
      setHideThirdNavBar(false);
      setHeaderHeight(headerHeightThreeColumn);
      if (window.location.pathname === "/exceptions") {
        setTertiaryNavSelection(0);
      } else if (window.location.pathname === "/deferrals") {
        setTertiaryNavSelection(1);
      } else if (window.location.pathname === "/history") {
        setTertiaryNavSelection(2);
      } else if (window.location.pathname === "/directpay") {
        setHideThirdNavBar(true);
        setHeaderHeight(headerHeightTwoColumn);
        setSecondaryNavSelection(1);
        setTertiaryNavSelection(null);
      } else if (window.location.pathname === "/payments-pending") {
        setHideThirdNavBar(false);
        setSecondaryNavSelection(2);
        setTertiaryNavSelection(0);
      } else if (window.location.pathname === "/deferrals-pending") {
        setHideThirdNavBar(false);
        setSecondaryNavSelection(2);
        setTertiaryNavSelection(1);
      } else if (window.location.pathname === "/history-pending") {
        setHideThirdNavBar(false);
        setSecondaryNavSelection(2);
        setTertiaryNavSelection(2);
      }
    }
  }, [window.location.pathname, currentPermissions]);

  useEffect(() => {
    secondaryNavSelection == 1
      ? setHeaderHeight(headerHeightTwoColumn)
      : setHeaderHeight(headerHeightThreeColumn);
  }, [secondaryNavSelection]);

  useEffect(() => {
    setLinks([
      {
        order: 0,
        title: "Exceptions",
        href: "/exceptions",
      },
      {
        order: 1,
        title: "Deferrals",
        href: "/deferrals",
      },
      {
        order: 2,
        title: "History",
        href: "/history",
      },
    ]);
    if (links.length > 0) {
      setLoading(false);
    }
  }, [links.length]);

  useEffect(() => {
    getExceptionCount();
    getDeferralsCount();
  }, [companies, getDeferralsCount, getExceptionCount]);

  useEffect(() => {
    if (currentPermissions.length === 0 && userIsAuthenticated()) {
      if (window.CONFIG) {
        const permissionsUrl = `${window.CONFIG.AUTH_API_URL}/auth/securedcomponents`;
        fetchApi("GET", permissionsUrl).then((response) => {
          if (response.status === 200) {
            setCurrentPermissions(response.data);
          } else {
            throw new Error(`Error Code ${response.status}`);
          }
        });
      }
    }
  }, [currentPermissions.length]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const allyProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const HasAccessToGlobalDirectPay = () => {
    return !!currentPermissions.find(
      (p) => p.component === "GDPExceptions" && p.accessLevel !== "X"
    );
  };

  const HasAccessToPendingExceptions = () => {
    return !!currentPermissions.find(
      (p) => p.component === "PendingExceptions" && p.accessLevel !== "X"
    );
  };

  const HasAccessToPhonePayExceptions = () => {
    return !!currentPermissions.find(
      (p) => p.component === "DSPExceptions" && p.accessLevel !== "X"
    );
  };

  const handlePrimaryNavChange = (event, newValue) => {
    setPrimaryNavSelection(newValue);
  };

  const handleSecondaryNavChange = (event, newValue) => {
    setHideThirdNavBar(
      event.currentTarget.innerHTML === "Global Direct Pay Exceptions"
    );
    setSecondaryNavSelection(newValue);
  };

  const handleTertiaryNavChange = (event, newValue) => {
    setTertiaryNavSelection(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  if (!loading && userIsAuthenticated()) {
    return (
      <PermissionsContext.Provider value={currentPermissions}>
        <CompaniesContext.Provider value={companies}>
          <SelectedCompaniesProvider>
            <CountsContext.Provider value={updateCountsCallbacks}>
              <AppBar
                position="static"
                color="default"
                elevation={1}
                className={classes.header}
                sx={{ height: headerHeight }}
              >
                <div className={classes.navBar}>
                  <div
                    id="logoContainer"
                    className={classes.logoContainer}
                  >
                    <img
                      src={BrandMark}
                      alt="Global Holdings"
                      width="80"
                    />
                  </div>
                  <div className={classes.menu}>
                    <Tabs
                      value={primaryNavSelection}
                      onChange={handlePrimaryNavChange}
                      className={classes.tabs}
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                    >
                      <Tab
                        label="Exceptions"
                        className={classes.menuLink}
                        {...allyProps(0)}
                      />
                    </Tabs>
                  </div>
                </div>
                <div className={classes.subNavBar}>
                  <TabPanel
                    value={primaryNavSelection}
                    index={0}
                  >
                    <Tabs
                      onChange={handleSecondaryNavChange}
                      className={classes.tabsPrimary}
                      value={secondaryNavSelection}
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                      sx={{ selected: { color: "white" } }}
                    >
                      {HasAccessToPhonePayExceptions() ? (
                        <Tab
                          label="Phone Pay Exceptions"
                          className={classes.subMenuLink}
                          component={NavLink}
                          to="exceptions"
                          {...allyProps(0)}
                        />
                      ) : null}

                      {HasAccessToGlobalDirectPay() ? (
                        <Tab
                          label="Global Direct Pay Exceptions"
                          className={classes.subMenuLink}
                          component={NavLink}
                          to="directpay"
                          {...allyProps(1)}
                        />
                      ) : null}
                      {HasAccessToPendingExceptions() ? (
                        <Tab
                          component={NavLink}
                          label="Pending Exceptions"
                          to="/payments-pending"
                          className={classes.subMenuLink}
                          {...allyProps(2)}
                        />
                      ) : null}
                    </Tabs>
                  </TabPanel>
                </div>
                {hideThirdNavBar ? null : (
                  <div className={classes.subSubNavBar}>
                    <TabPanel
                      value={secondaryNavSelection}
                      index={1}
                    ></TabPanel>
                    <TabPanel
                      value={secondaryNavSelection}
                      index={0}
                    >
                      <Tabs
                        value={tertiaryNavSelection}
                        className={classes.tabs}
                        onChange={handleTertiaryNavChange}
                        TabIndicatorProps={{
                          style: { display: "none" },
                        }}
                      >
                        <Tab
                          component={NavLink}
                          label={`Exceptions (${counts[0]})`}
                          className={classes.subSubMenuLink}
                          to="/exceptions"
                          {...allyProps(0)}
                        />
                        <Tab
                          component={NavLink}
                          label={`Deferrals (${counts[1]})`}
                          className={classes.subSubMenuLink}
                          to="/deferrals"
                          {...allyProps(1)}
                        />
                        <Tab
                          component={NavLink}
                          label="History"
                          className={classes.subSubMenuLink}
                          to="/history"
                          {...allyProps(2)}
                        />
                      </Tabs>
                    </TabPanel>
                    <TabPanel
                      value={secondaryNavSelection}
                      index={2}
                    >
                      <Tabs
                        value={tertiaryNavSelection}
                        className={classes.tabs}
                        onChange={handleTertiaryNavChange}
                        TabIndicatorProps={{
                          style: { display: "none" },
                        }}
                      >
                        <Tab
                          component={NavLink}
                          label={`Exceptions (${counts[2]})`}
                          className={classes.subSubMenuLink}
                          to="/payments-pending"
                          {...allyProps(0)}
                        />
                        <FeatureFlag flagName="DeferralsActive">
                          <Tab
                            component={NavLink}
                            label={`Deferrals (${counts[3]})`}
                            className={classes.subSubMenuLink}
                            to="/deferrals-pending"
                            {...allyProps(1)}
                          />
                        </FeatureFlag>
                      </Tabs>
                    </TabPanel>
                  </div>
                )}
              </AppBar>
              <SessionTimer
                sessionTime={20}
                sessionCallback={handleOpenSessionModal}
              />
              <SessionModal
                open={sessionModalOpen}
                counter={120}
                timeOutFunction={() => {
                  logOut(
                    `${window.CONFIG.AUTH_UI_URL}/?referrer=UI.Exceptions`
                  );
                }}
                handleModalAction={handleCloseSessionModal}
              />
              <div className={classes.contentContainer}>{props.children}</div>
            </CountsContext.Provider>
          </SelectedCompaniesProvider>
        </CompaniesContext.Provider>
      </PermissionsContext.Provider>
    );
  } else {
    return <Fragment>{props.children}</Fragment>;
  }
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: theme.spacing(2),
    position: "relative",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    padding: 0,
    position: "relative",
    "& h1": {
      fontWeight: 400,
      fontSize: "150%",
    },
  },
  menu: {
    display: "flex",
  },
  navBar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: theme.spacing(0.1),
  },
  subNavBar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;",
  },
  subSubNavBar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  menuLink: {
    borderRadius: 0,
    padding: theme.spacing(1, 3),
    height: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    borderBottom: `${theme.spacing(0.5)} solid transparent`,
    "&.active": {
      color: theme.palette.primary.main,
      borderBottom: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
    },
  },
  tabs: {
    indicator: {
      background: "none",
    },
    "& button[aria-selected='true']": {
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  },
  tabsPrimary: {
    indicator: {
      background: "none",
    },
    "& button[aria-selected='true']": {
      borderBottom: `5px solid white`,
    },
  },
  subMenuLink: {
    color: "white !important",
    borderBottom: `${theme.spacing(0.5)} solid transparent`,
    "&.active[aria-selected='true']": {
      borderBottom: `${theme.spacing(0.5)} solid white`,
    },
    "[aria-selected='true']": {
      borderBottom: `${theme.spacing(0.5)} solid white`,
    },
  },
  subSubMenuLink: {
    color: "#333",
    borderBottom: `${theme.spacing(0.5)} solid transparent`,
    "&.active": {
      color: theme.palette.primary.main,
      borderBottom: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
    },
  },
  logoContainer: {
    display: "flex",
    height: theme.spacing(10),
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    "& img": {
      width: theme.spacing(4),
      padding: theme.spacing(2, 3),
      "& .st0": {
        fill: "#fff !important",
        stroke: "#fff !important",
      },
    },
  },
  badge: {
    "& span": {
      top: theme.spacing(3),
      right: theme.spacing(1),
    },
  },
}));

export default withRouter(Layout);
