import React, { Fragment, useState, useEffect } from "react";
import getApiEnvironment from "../../helpers/GetApiEnvironment";
import { fetchApi } from "@globalholdings/ui-common-functions";

export default function FeatureFlag(props) {
  const [flagStatus, setFlagStatus] = useState(false);

  const fetchFeatureFlag = () => {
    const api = `${getApiEnvironment(false)}/featureflags/${props.flagName}`;
    fetchApi("GET", api)
      .then((response) => {
        if (response.status === 200) {
          const flagsForCurrentApp = response.data.filter((item) => {
            return item.appName === "UI.Exceptions";
          });
          if (flagsForCurrentApp[0].flagValue === "true") {
            setFlagStatus(true);
          }
        } else {
          //fail silently
        }
      })
      .catch((error) => {
        //fail silently
      });
  };

  useEffect(() => {
    fetchFeatureFlag();
  }, []);

  return flagStatus && <Fragment>{props.children}</Fragment>;
}
