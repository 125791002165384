import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { unregister } from "./serviceWorker";
import App from "./containers/App";
import "./styles.css";

require("typeface-poppins");

unregister();
const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#1373e6",
        light: "#fff",
      },
      secondary: {
        main: "#fff",
        contrastText: "#1373e6",
      },
    },
    typography: {
      fontFamily: [
        "Poppins",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },

    overrides: {
      MuiButton: {
        root: {
          textTransform: "none",
          padding: "6px 32px",
          borderRadius: "24px",
        },
        text: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow:
              "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 0px 3px 0px rgba(0,0,0,0.12)",
          },
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: "#eee",
          },
        },
      },
      MuiAppBar: {
        root: {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
          height: 60,
        },
        colorDefault: {
          backgroundColor: "#ffffff",
        },
      },
      MuiDialogTitle: {
        root: {
          padding: "16px 40px",
          borderBottom: "1px solid #eee",
        },
      },
      MuiTab: {
        root: {
          flexShrink: 1,
          textTransform: "none",
          "@media (min-width: 600px)": {
            minWidth: "80px",
          },
        },
      },
      PrivateTabIndicator: {
        root: {
          bottom: "10px",
          height: "3px",
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
      MuiTypography: {
        h1: {
          fontSize: "1.8rem",
          marginBottom: ".5rem",
        },
        h2: {
          fontSize: "1rem",
          fontWeight: "600",
        },
        body2: {
          fontSize: "0.75rem",
        },
      },
      MuiOutlinedInput: {
        input: {
          padding: "12px 14px",
        },
      },
      MuiInputLabel: {
        outlined: {
          transform: "translate(14px, 12px) scale(1)",
        },
      },
      MuiTable: {
        root: {
          border: "1px solid #ddd",
        },
      },
      MuiTableCell: {
        root: {
          borderLeft: "1px solid #ddd",
          borderBottom: "none",
        },
        sizeSmall: {
          padding: "4px 8px",
        },
      },
      MuiTableHead: {
        root: {
          borderBottom: "1px solid #ddd",
        },
      },
      MuiTableFooter: {
        root: {
          borderTop: "1px solid #ddd",
        },
      },
      MuiTablePagination: {
        spacer: {
          display: "none",
        },
      },
      MuiSvgIcon: {
        fontSizeSmall: {
          fontSize: "1.15rem",
        },
      },
    },
  })
);

render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
