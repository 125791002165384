export default function getApiEnvironment(isGateway = true) {
  const hostname = window.location.hostname;
  let apiBrand;
  apiBrand = "ghllc";

  if (isGateway) {
    try {
      // Use the Dev API in dev & local environments
      if (
        hostname.indexOf("dev") !== -1 ||
        hostname.indexOf("localhost") !== -1
      ) {
        //return "https://localhost:3004";
        //return "http://localhost:56420";
        return "https://dev.api";
      }
      // QA API in QA environment
      else if (hostname.indexOf("qa") !== -1) {
        return "https://qa.api";
      }
      //Test environment
      else if (hostname.indexOf("test") !== -1) {
        return "https://test.api";
      }
      // Prod
      else if (hostname.indexOf("exceptions") !== -1) {
        return "https://api";
      } else {
        throw new Error(
          "API could not be found. Please connect to this site via a URL instead of an IP address."
        );
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    try {
      //Use the Dev API for local development
      if (hostname.indexOf("localhost") !== -1) {
        return `https://dev.restapi.${apiBrand}.com`;
      }
      //if (hostname.indexOf("localhost") !== -1) {
      //  return `http://localhost:18222`;
      //}
      // Dev API in dev environment
      else if (hostname.indexOf("dev") !== -1) {
        return `https://dev.restapi.${apiBrand}.com`;
      }
      // QA API in QA environment
      else if (hostname.indexOf("qa") !== -1) {
        return `https://qa.restapi.${apiBrand}.com`;
      }
      // Test
      else if (hostname.indexOf("test") !== -1) {
        return `https://test.restapi.${apiBrand}.com`;
      }
      // Demo
      // @todo: We may need to build the API for Demo and then update this path.
      else if (hostname.indexOf("demo") !== -1) {
        return `https://qa.restapi.${apiBrand}.com`;
      }
      // Prod
      else if (hostname.indexOf("myaccount") !== -1) {
        return `https://restapi.${apiBrand}.com`;
      } else {
        throw new Error(
          "API could not be found. Please connect to this site via a URL instead of an IP address."
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
}
