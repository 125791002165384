import React, { useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
// Custom imports
import { userIsAuthenticated } from "@globalholdings/ui-common-functions";
import { PermissionsContext } from "../../context/Permissions";

export default function LoginRedirect(props) {
  const classes = useStyles();
  const currentPermissions = useContext(PermissionsContext);
  const { loadingComponent: Loading } = props;
  useEffect(() => {
    if (userIsAuthenticated()) {
      if (window.location.pathname !== "/" || currentPermissions.length == 0) {
        return;
      }
      if (
        !!currentPermissions.find(
          (p) => p.component === "DSPExceptions" && p.accessLevel !== "X"
        )
      ) {
        window.location.assign("/exceptions");
      } else if (
        !!currentPermissions.find(
          (p) => p.component === "GDPExceptions" && p.accessLevel !== "X"
        )
      ) {
        window.location.assign("/directpay");
      } else {
        window.location.assign("/payments-pending");
      }
    } else {
      if (window.CONFIG.AUTH_UI_URL) {
        window.location.assign(
          `${window.CONFIG.AUTH_UI_URL}/?referrer=${window.CONFIG.APP_NAME}`
        );
      }
    }
  }, [currentPermissions.length]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.iconContainer}>
        <Loading />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    "& div": {
      padding: theme.spacing(10, 0),
    },
  },
}));
